// extracted by mini-css-extract-plugin
export var bodyBase = "edit-tournament-module--body-base--8LyMe edit-tournament-module--site-font--JyleG";
export var bodyLarge = "edit-tournament-module--body-large--l53GV edit-tournament-module--body-base--8LyMe edit-tournament-module--site-font--JyleG";
export var bodyLargeBold = "edit-tournament-module--body-large-bold--vYSq2 edit-tournament-module--body-base--8LyMe edit-tournament-module--site-font--JyleG";
export var bodyRegular = "edit-tournament-module--body-regular---zPqR edit-tournament-module--body-base--8LyMe edit-tournament-module--site-font--JyleG";
export var bodyRegularBold = "edit-tournament-module--body-regular-bold--8tICH edit-tournament-module--body-base--8LyMe edit-tournament-module--site-font--JyleG";
export var bodySmall = "edit-tournament-module--body-small--du2jw edit-tournament-module--body-base--8LyMe edit-tournament-module--site-font--JyleG";
export var bodySmallBold = "edit-tournament-module--body-small-bold--8pjcZ edit-tournament-module--body-base--8LyMe edit-tournament-module--site-font--JyleG";
export var borderTop = "edit-tournament-module--border-top--gdF2s";
export var breakWordContainer = "edit-tournament-module--break-word-container--JYNun";
export var buttonIconBase = "edit-tournament-module--button-icon-base--6m+X+";
export var clickLink = "edit-tournament-module--click-link--IgCU4";
export var container = "edit-tournament-module--container--2fiAJ";
export var dropdownBase = "edit-tournament-module--dropdown-base--PzfxB";
export var dropdownSelectBase = "edit-tournament-module--dropdown-select-base--0o9OT edit-tournament-module--text-input--0lmVZ";
export var errorIcon = "edit-tournament-module--error-icon--18ovj";
export var flexCol = "edit-tournament-module--flex-col--8bYJm";
export var formErrorMessage = "edit-tournament-module--form-error-message--zWczZ";
export var h3 = "edit-tournament-module--h3--Wf7cq";
export var h4 = "edit-tournament-module--h4--jIo2f";
export var hoverLink = "edit-tournament-module--hover-link--kWtjP";
export var hoverRow = "edit-tournament-module--hover-row--r0gNw";
export var iframe = "edit-tournament-module--iframe--Z7fsb";
export var membershipContainer = "edit-tournament-module--membership-container--oOwBA edit-tournament-module--flex-col--8bYJm edit-tournament-module--primary-border--dEpl2";
export var membershipHeader = "edit-tournament-module--membership-header--CIxQI";
export var membershipHeading = "edit-tournament-module--membership-heading--7skU1";
export var membershipLabel = "edit-tournament-module--membership-label--hI7cl";
export var moreFiltersBorderClass = "edit-tournament-module--more-filters-border-class--0rwo5";
export var pageBg = "edit-tournament-module--page-bg--Yxo7B";
export var pointer = "edit-tournament-module--pointer--rZLYI";
export var primaryBorder = "edit-tournament-module--primary-border--dEpl2";
export var shadowBoxLight = "edit-tournament-module--shadow-box-light--Ktv9+";
export var siteFont = "edit-tournament-module--site-font--JyleG";
export var slideDownAndFade = "edit-tournament-module--slideDownAndFade--XObMm";
export var slideLeftAndFade = "edit-tournament-module--slideLeftAndFade--4fYoK";
export var slideRightAndFade = "edit-tournament-module--slideRightAndFade--qjgH6";
export var slideUpAndFade = "edit-tournament-module--slideUpAndFade--Wzaxx";
export var spinner = "edit-tournament-module--spinner--F3FSh";
export var statusDecoration = "edit-tournament-module--status-decoration--J7iah";
export var textInput = "edit-tournament-module--text-input--0lmVZ";
export var textInverted = "edit-tournament-module--text-inverted--G38L7";
export var textMediumDark = "edit-tournament-module--text-medium-dark--GYFaw";
export var tooltipFont = "edit-tournament-module--tooltipFont--fznB4";
export var unstyledButton = "edit-tournament-module--unstyled-button--lSJkS";