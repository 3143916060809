import React from 'react'
import Layout from 'src/components/layout/layout'
import SEO from 'src/components/seo'
import { usePrivateRoute } from 'src/utils/auth'
import EditTournament from 'src/components/edit-tournament/edit-tournament'
import { RouteComponentProps } from '@reach/router'
import { Router } from '@reach/router'

const EditTournamentPage = () => {
  usePrivateRoute()
  return (
    <Router basepath="/tournaments/edit-tournament">
      <EditTournamentRoute path="/:tournamentId" />
    </Router>
  )
}

interface EditTournamentRouteProps extends RouteComponentProps {
  tournamentId?: string
}

const EditTournamentRoute: React.FC<EditTournamentRouteProps> = ({ tournamentId }) => {
  return (
    <Layout noPadding>
      <SEO title="Edit Tournament" />
      <EditTournament tournamentId={tournamentId} />
    </Layout>
  )
}

export default EditTournamentPage
