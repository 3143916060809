import React, { useState } from 'react'
import * as styles from './edit-tournament.module.less'
import { useQuery } from '@apollo/client'
import { GET_URL_SEGMENT } from './edit-tournament-queries'
import { GetUrlSegment, GetUrlSegmentVariables } from 'src/graphql-types/GetUrlSegment'
import Icon from '../icon/icon'
import { getEnvConfig } from 'src/config/config'
import Spinner from '../spinner/spinner'
import { useOrgId } from 'src/apollo/local-state'
import { tournamentsClient } from 'src/apollo/client'

interface Props {
  tournamentId?: string
}

const EditTournament: React.FC<Props> = ({ tournamentId }) => {
  const { data, error } = useQuery<GetUrlSegment, GetUrlSegmentVariables>(GET_URL_SEGMENT, {
    client: tournamentsClient,
    variables: { id: tournamentId }
  })
  const urlSegment = data?.tournament.organisation?.urlSegment
  const [loading, setLoading] = useState(true)
  const orgId = useOrgId()

  return (
    <div className={styles.container}>
      {(loading || error) && (
        <div className={styles.spinner}>
          {loading && !error && <Spinner />}
          {error && (
            <>
              <Icon name="sm-warning" className={styles.errorIcon} /> {error.message}
            </>
          )}
        </div>
      )}
      {urlSegment && orgId && (
        <iframe
          src={`${
            getEnvConfig().CLUBSPARK_CLASSIC_ADMIN_SITE_IFRAME
          }/${urlSegment}/Admin/Tournaments/Tournament/${tournamentId}?providerId=${orgId}`}
          className={styles.iframe}
          onLoad={() => setLoading(false)}
        />
      )}
    </div>
  )
}

export default EditTournament
